.noPoint {
  padding: 1rem;
  color: #ffffff;
  font-family: Popins;
  text-align: center;
  > .np_title {
    margin: 1.5rem 0;
    font-size: 1.4rem;
  }
  > .np_desc {
    span:nth-child(1) {
      font-size: 1.5rem;
      color: var(--app-primary-color);
    }
    span:nth-child(2) {
      font-size: 0.8rem;
      margin-left: 5px;
    }
  }
  > .np_action {
    background-color: #272727;
    margin: 2rem 1rem;
    border-radius: 5px;
    display: flex;
    padding: 1rem 1.5rem;
    > div:nth-of-type(1),
    > div:nth-of-type(3) {
      height: 2rem;
      width: 2rem;
      border: 1px solid #ffffff;
      line-height: 1.8rem;
      border-radius: 2rem;
      margin-top: 0.5rem;
      cursor: pointer;
      user-select: none;
      &:hover {
        border-color: var(--app-primary-color);
        color: var(--app-primary-color);
      }
    }
    > div:nth-of-type(2) {
      width: 80%;
      > div:nth-of-type(1) {
        > span:nth-of-type(1) {
          margin-right: 5px;
        }
      }
      > div:nth-of-type(2) {
        font-family: Popins;
        font-size: 0.9rem;
        color: #b1adad;
      }
    }
  }
  > .np_point {
    > span:nth-of-type(1) {
      margin-right: 5px;
    }
    > span:nth-of-type(2) {
      color: var(--app-primary-color);
    }
  }
  > .np_buy {
    width: 70%;
    background: linear-gradient(180deg, #cefe00 0%, #fee600 100%);
    margin: 3rem auto;
    color: #000000;
    border-radius: 5px;
    height: 2.5rem;
    line-height: 2.5rem;
    cursor: pointer;
  }
  > .np_b_desc {
    font-family: Popins;
    font-size: 0.8rem;
  }
}

