.container {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  z-index: 999;
  overflow: auto;
  > .main {
    height: fit-content;
    // padding: 1rem;
    background-color: #151515;
    border-radius: 1rem;
    position: relative;
    border: 1px solid #1e1e1e;
    overflow-x: hidden;
    > .icon {
      position: absolute;
      right: 1rem;
      top: 1rem;
      cursor: pointer;
      z-index: 999;
      color: #ffffff !important;
      &:hover {
        color: var(--app-primary-color) !important;
      }
    }
  }
}

