.globalFooterWarp {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  max-width: 1200px;
  z-index: 1;
  flex-direction: column;
  color: var(--app-text-color);
  background: var(--foreground-rgb);
  padding: 0 1rem;
  margin-bottom: 3rem;
  margin-top: 1rem;
  text-align: center;

  > .titleContainer {
    font-size: 24px;
    font-weight: bold;
    line-height: 1.2;
    // border-bottom: 1px solid #cebefe;
    width: 100%;
    // padding-bottom: 44px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logo {
      margin: 3rem 1rem;
      text-align: left;
      img:nth-of-type(1) {
        height: 3rem;
      }
    }

    .name {
      display: inline-block;
      height: 32px;
      width: auto;
    }
    > .sectionIcon {
      &:nth-child(1) {
        margin-right: 10px;
      }
    }

    > .section {
      display: flex;
      // justify-content: space-evenly;
      > .link {
        text-align: center;
        font-size: 16px;
        font-weight: 700;
        line-height: 19px;
        color: var(--app-text-color);
        // font-family: Montserrat;
        transition: all 0.3s ease-in;
        // padding: auto 5px;
        margin: 0 50px;

        &:hover {
          color: var(--app-primary-color);
        }

        img {
          width: 32px;
          height: 32px;
        }
      }
    }
    > .circle {
      margin: 2rem 1rem 1rem 0rem;
      text-align: left;
      img {
        height: 2rem;
        margin-right: 5px;
        cursor: pointer;
      }
    }
  }
  .bottom {
    padding-top: 2rem;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #6e6e6e;
    > .policy {
      padding-left: 1rem;
      font-size: 1rem;
      text-align: left;
      > span:nth-of-type(2) {
        margin: 0 1rem;
      }
      > span:nth-child(1),
      > span:nth-of-type(3) {
        cursor: pointer;
        &:hover {
          color: var(--app-primary-color);
          text-decoration: underline;
        }
      }
    }

    > .subTitleContainer {
      padding-left: 1rem;
      font-weight: 500;
      font-size: 1rem;
      text-align: left;
    }
  }
}
@media (max-width: 900px) and (min-width: 768px) {
  .link {
    margin: 0 20px !important;
  }
}

@media (max-width: 769px) and (min-width: 576px) {
  .link {
    margin: 0 10px !important;
  }
}

@media screen and (max-width: 577px) {
  .globalFooterWarp {
    margin-top: 2rem;
    border-top: 1px solid #9e9e9e;
  }
  .link {
    margin: 0 10px !important;
  }
  .titleContainer {
    display: block !important;
  }
  .bottom {
    border-top: none !important;
  }
  .bottom {
    display: block !important;
    padding-top: 0.5rem !important;
    margin-bottom: 2rem !important;
    > div:nth-child(1) {
      margin-bottom: 0.5rem;
    }
  }
}

