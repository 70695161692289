.navItemWarp {
  cursor: pointer;
  margin: 0 16px;
  // padding: 20px 0;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  position: relative;
  // background: var(--foreground-rgb);

  :global {
    .anticon {
      margin-left: 6px;
    }
  }

  > .navItemText {
    font-family: Montserrat;
    line-height: 0.8;
    font-weight: 500;
    color: var(--app-text-color);
    text-align: center;
  }
  > .navItemTextBottom {
    height: 10px;
  }

  > .navItemMobileText {
    font-family: Montserrat;
    line-height: 1.2;
    color: var(--app-text-color);
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 20px;
  }

  > .navDropdown {
    z-index: 1;
    background: var(--foreground-rgb);
    padding: 2rem 1.5rem;
    border: 2px solid var(--app-text-color);
    position: absolute;
    top: 60px;
    right: 0;
    flex-direction: column;
    width: 250px;
    display: none;

    &:hover {
      display: flex;
    }

    > .navItemWarp {
      margin: 0 0 15px;
      padding: 0;
      width: 100%;
      align-items: flex-start;
      justify-content: flex-start;

      &:last-child {
        margin: 0;
      }
    }
  }

  &:hover {
    > .navItemText {
      color: var(--app-link-hover);
    }

    > .navDropdown {
      display: flex;
    }
  }
}

@media screen and (max-width: 900px) {
  .navItemWarp {
    justify-content: flex-start;
    > .navItemText {
      font-size: 1rem;
    }
  }
}

// @media (max-width: 950px) and (min-width: 900px) {
//   .navItemWarp {
//     width: 100%;
//     // box-sizing: border-box;
//     justify-content: flex-start;
//   }
// }

@media (max-width: 980px) and (min-width: 900px) {
  .navItemWarp {
    > .navItemText {
      font-size: 1rem;
    }
  }
}

@media (max-width: 1280px) and (min-width: 980px) {
  .navItemWarp {
    > .navItemText {
      font-size: 1rem;
    }
  }
}

@media screen and (min-width: 1280px) {
  .navItemWarp {
    > .navItemText {
      font-size: 1rem;
    }
  }
}

