.mobileHeaderWarp {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: var(--foreground-rgb);
  // border: 2px solid #000;
  padding: 1rem;
  z-index: 4;

  > .navToolbar {
    display: flex;
    align-items: center;
    justify-content: space-between;

    > .navToolbarSection {
      display: flex;
      align-items: center;
      // width: calc(100% / 3);
      color: var(--app-text-color);
      cursor: pointer;
      &:nth-child(1) {
        justify-content: flex-start;
      }

      &:nth-child(2) {
        justify-content: center;
      }

      &:nth-child(3) {
        justify-content: flex-end;
      }

      > .logo {
        > .pietra {
          width: 32px;
          height: 32px;
        }
      }

      > .searchIcon {
        // transform: translateX(5px);
        margin-left: 16px;
      }

      > .cartIcon {
        transform: translateY(2px);
      }
      .avatar {
        height: 2rem;
        width: 2rem;
        background-color: var(--app-primary-color);
        text-align: center;
        line-height: 2rem;
        color: #000000;
        border-radius: 2rem;
        cursor: pointer;
      }
    }
    > .desc {
      color: #ffffff;
    }
  }
}

