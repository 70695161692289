.homeContent {
  // width: 100%;
  display: flex;
  gap: 28px;
  .leftContent {
    width: 710px;
    .leftContainer {
      padding: 24px;
      border: 2px solid #141414;
      .bread {
        :global {
          .next-antd-breadcrumb-separator {
            color: #b8b8b8 !important;
          }
        }
      }
      // width: 100%;
      .crumbs {
        margin-bottom: 15px;
      }

      > .time {
        color: #b8b8b8;
        font-size: 14px;
        padding-bottom: 12px;
      }

      > .title {
        color: var(--app-text-color);
        font-size: 32px;
        line-height: 1.2;
      }
      > .image {
        width: 100%;
        height: auto;
        padding-top: 32px;
      }

      > .desc {
        color: var(--app-text-color);
        font-size: 16px;
        font-weight: 500;
        padding-top: 32px;
      }
      > .content {
        color: var(--app-text-color) !important;
        margin-top: 32px;
        font-size: 16px;

        h1 {
          font-size: 32px !important;
          margin: 32px 0 !important;
          font-weight: 800 !important;
          color: var(--app-text-color) !important;
        }

        h2 {
          font-size: 28px !important;
          margin: 26px 0 !important;
          font-weight: 600 !important;
          color: var(--app-text-color) !important;
        }

        h3 {
          font-size: 24px !important;
          margin: 18px 0 !important;
          font-weight: 500 !important;
          color: var(--app-text-color) !important;
        }

        h4 {
          font-size: 20px !important;
          margin: 12px 0 !important;
          color: var(--app-text-color) !important;
        }

        p {
          margin-bottom: 32px !important;
          color: var(--app-text-color) !important;
        }

        ol {
          padding-left: 24px !important;
          margin-bottom: 24px !important;
          color: var(--app-text-color) !important;
        }

        ul {
          margin-left: 20px !important;
          margin-bottom: 24px !important;
          color: var(--app-text-color) !important;
        }

        li {
          font-size: 16px !important;
          color: var(--app-text-color) !important;
        }

        a {
          color: var(--app-link-color) !important;

          span {
            color: var(--app-link-color) !important;
          }
        }

        span {
          color: var(--app-text-color) !important;
        }

        img {
          max-width: 100% !important;
        }
      }

      > .recommendTags {
        margin-bottom: 32px;

        h2 {
          font-size: 28px !important;
          margin-bottom: 28px !important;
          font-weight: 600 !important;
          color: var(--app-text-color) !important;
        }

        .linkContainer {
          margin-bottom: 10px;

          span {
            color: var(--app-text-color);
          }

          .link {
            color: var(--app-link-color);
            font-size: 16px;
            padding-left: 5px;
          }
        }
      }

      > .tagContiner {
        border-top: 1px solid var(--app-line-bg-rgb);
        padding: 32px 0;

        > .tagTitle {
          color: var(--app-text-60-color);
          font-size: 16px;
          padding-right: 10px;
        }

        > .tagContent {
          color: var(--app-link-color);
          font-size: 16px;
          padding-right: 10px;
          cursor: pointer;
        }
      }
    }

    .otherContainer {
      // margin-top: 32px;
      padding-top: 32px;
      border-top: 1px solid var(--app-line-bg-rgb);

      > h3 {
        color: var(--app-text-color);
        font-size: 24px;
        font-weight: 900;
        margin-bottom: 12px;
      }
    }
  }
  .rightContent {
    width: 282px;
    // background-color: red;
    display: flex;
    flex-direction: column;
    gap: 18px;

    .nav {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      background-image: url("https://res-front.pumpsoul.com/prod-cobra/image/banner.png");
      background-size: 100% 100%;
      padding: 26px 56px 26px 30px;
      .title {
        font-size: 16px;
        color: #fff;
        margin-bottom: 20px;
        font-weight: 700;
      }
      .button {
        width: 100%;
        font-size: 14px;
        height: 38px;
        text-wrap: wrap;
        line-height: 1;
        padding: initial;
      }
    }

    .sampleAccountContent {
      width: 100%;
      background: #ffffff;
      border-radius: 12px 12px 12px 12px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      padding: 20px;

      .title {
        font-weight: 600;
        font-size: 16px;
        margin-bottom: 11px;
      }

      .desc {
        font-size: 14px;
        color: var(--app-text-60-color);
      }

      .accountList {
        .accountItem {
          display: flex;
          padding: 16px 0;

          .avatar {
            width: 45px;
            height: 45px;
            margin-right: 18px;
          }
          .rightContents {
            flex: 1;
            .accountName {
              font-weight: 600;
            }
            .linkButton {
              display: inline-block;
              vertical-align: bottom;
              font-size: 14px;
              color: var(--app-primary-color);
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .homeContent {
    flex-direction: column;
    .leftContent {
      width: auto;
    }
    .rightContent {
      width: 100%;
      background-size: cover;
      flex-direction: row;

      .nav {
        flex: 1;
      }

      .sampleAccountContent {
        flex: 1;
        order: -1;
      }
    }
  }
}

@media screen and (max-width: 575px) {
  .homeContent {
    .rightContent {
      width: 100%;
      background-size: cover;
      flex-direction: column;
      .nav {
        height: 300px;
        background-size: 100% 100%;
        background-position: center;
        border-radius: 20px;
      }
    }
  }
}

